/* eslint-disable no-case-declarations */
import SpatialAdjustEntityType = ToroEnums.SpatialAdjustEntityType;

import { catchError, map, take } from 'rxjs/operators';
import { forkJoin, Observable, of, Subject, timeout } from 'rxjs';
import { IMqttMessage, MqttConnectionState, MqttService } from 'ngx-mqtt';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppInjector } from '../../demo/demo.module';
import { AuthManagerService } from '../auth/auth-manager.service';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { duration } from 'moment';
import { environment } from '../../../environments/environment';
import { GenericMqttMessage } from './models/generic-mqtt-message.model';
import { GetCourseReportMessage } from './models/mqtt-get-course-report/get-course-report-message.model';
import { GetCourseReportStation } from './models/mqtt-get-course-report/get-course-report-station.model';
import { GetCoursesCourse } from './models/mqtt-get-courses/get-courses-course.model';
import { GetCoursesMessage } from './models/mqtt-get-courses/get-courses-message.model';
import { GetGeoTopoArea } from './models/mqtt-get-geo-topo/get-geo-topo-area.model';
import { GetGeoTopoMessage } from './models/mqtt-get-geo-topo/get-geo-topo-message.model';
import { GetProgramTopoArea } from './models/mqtt-get-program-topo/get-program-topo-area.model';
import { GetProgramTopoMessage } from './models/mqtt-get-program-topo/get-program-topo-message.model';
import { GetStationsMessage } from './models/mqtt-get-stations/get-stations-message.model';
import { GetStationsStation } from './models/mqtt-get-stations/get-stations-station.model';
import { Injectable } from '@angular/core';
import { LynxCloudApiService } from './lynx-cloud-api.service';
import { LynxCloudCourseItem } from '../../core/dashboard/widgets/lynx/widget-lynx-cloud/models/lynx-cloud-course-item.model';
import { LynxPercentAdjust } from './models/mqtt-percent-adjust/lynx-percent-adjust.model';
import { SaStationAdjustItem } from './models/sa-station-adjust-item.model';
import { StationAdjustDict } from './models/station-adjust-dict.model';
import { StationAdjustInfo } from './models/station-adjust-info.model';
import { StationStatuses } from './models/station-status/station-statuses.model';
import { StationStatusItem } from './models/station-status/station-status-item.model';
import { StationStatusStation } from './models/station-status/station-status-station.model';
import { ToroEnums } from '../../common/enumerations/toro.enums';
import { v4 as uuidv4 } from 'uuid';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class LynxCloudService {
    // Subjects
    private courseListRetrieved = new Subject<GetCoursesCourse[]>();
    private geoTopographyRetrieved = new Subject<GetGeoTopoArea[]>();
    private courseReportRetrieved = new Subject<GetCourseReportStation[]>();
    private stationsRetrieved = new Subject<GetStationsStation[]>();
    public spacialAdjustDataReady = new Subject<StationAdjustDict>();
    public stationAdjustListUpdated = new Subject<SaStationAdjustItem[]>();

    // Member Vars
    private readonly genericMessageId = '7da2fdb1-9c03-4982-cd52-ba3b65f45d99';
    private readonly clientId: string;
    private readonly installationIds: string[]; // = '000732893849';    // Muirfield for intelliDash2020@toro.com
    private isPendingInitialization = false;

    private lynxStations: GetStationsStation[] = [];
    private lynxPrograms: GetProgramTopoArea[] = [];
    private lynxPercentAdjustments: LynxPercentAdjust[] = [];
    private stationAdjustDict: StationAdjustDict = {};
    private stationAdjustList: SaStationAdjustItem[] = [];

    private spatialAdjustCourseItem: LynxCloudCourseItem;

    private demoModeMockDataService: DemoModeMockDataService;

    isInitialized = false;

    // =========================================================================================================================================================
    // C'tor
    // =========================================================================================================================================================

    constructor(authManager: AuthManagerService,
                private lynxCloudApiService: LynxCloudApiService,
                private mqttService: MqttService
    ) {
        if (environment.isDemoMode) {
            this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService);
            return;
        }

        // Get the LynxCloud Installation Id from the Dash User object. This will come from NSN.
        this.installationIds = authManager.dashAuthenticatedUser.LynxCloudInstallationIds;
        // this.installationIds.push('000732893849'); // For Testing Muirfield

        this.initMqttBroker();

        this.clientId = `client-${uuidv4()}`
    }

    // =========================================================================================================================================================
    // public Methods
    // =========================================================================================================================================================

    public initMqttBroker() {
        if (this.isPendingInitialization || this.isInitialized) return;

        this.isPendingInitialization = true;

        this.lynxCloudApiService.init()
            .subscribe({
                next: result => {
                    const bits = result.split('|#|');
                    this.mqttService.connect({ username: bits[0], password: bits[1] });
                    this.setupMqttListeners();
                },
                error: err => {
                    setTimeout(() => this.initMqttBroker(), 15000)
                }
            });
    }

    public getCourseList(): Observable<GetCoursesCourse[]> {
        if (environment.isDemoMode) {
            return of(this.demoModeMockDataService.lynxCloudCourses);
        }

        // If we don't have any Lynx Cloud Installation Ids, bale out.
        if (this.installationIds.length < 1 || this.installationIds[0] == '') return of(null);

        this.requestCoursesList();

        return Observable.create(observer => {
            let responseCount = 0;
            let courses: GetCoursesCourse[] = [];
            let retrievedInstallationIds: string[] = [];

            this.installationIds.forEach(id => {

                this.courseListRetrieved
                    .pipe(
                        timeout(5000),
                        catchError(err => {
                            if (++responseCount === this.installationIds.length) {
                                observer.next(courses);
                                observer.complete();
                            }

                            // Likely timeout
                            throw err;
                        }),
                    )
                    .subscribe(result => {
                        if (!retrievedInstallationIds.includes(result[0].installationId)) {
                            retrievedInstallationIds.push(result[0].installationId);
                            courses.push(...result);

                            if (++responseCount === this.installationIds.length) {
                                observer.next(courses);
                                observer.complete();
                            }
                        }
                    });

            });
        });

    }

    public getStationStatuses(courseItem: LynxCloudCourseItem): Observable<StationStatuses> {
        if (environment.isDemoMode) {
            return of(this.demoModeMockDataService.lynxCloudStationStatuses);
        }

        if (courseItem != null) this.spatialAdjustCourseItem = courseItem;
        if (this.spatialAdjustCourseItem == null) return;

        courseItem = this.spatialAdjustCourseItem;

        this.requestGeoTopography(courseItem);
        this.requestCourseReport(courseItem);
        this.requestStations(courseItem);

        // TODO: Correlate Station Data with PercentAdjust data for display and update.
        //       Need Lynx Cloud to provide this data.
        // this.requestPercentAdjustments(courseItem);
        // setTimeout(() => {
        //     this.requestChangePercentAdjust(courseItem);
        // }, 5000);

        // this.requestProgramTopo(courseItem);
        // this.requestPercentAdjustments(courseItem);
        // this.requestChangePercentAdjust(courseItem)

        const sources: Observable<any>[] = [
            this.geoTopographyRetrieved.pipe(take(1), timeout(5000)),
            this.courseReportRetrieved.pipe(take(1), timeout(5000)),
            this.stationsRetrieved.pipe(take(1), timeout(5000))
        ];

        return forkJoin(sources)
            .pipe(
                map(([geoTopoAreas, courseReportStations, stations]) => {
                    const areas = geoTopoAreas as GetGeoTopoArea[];

                    const { running, ranUnder } = areas.reduce((acc, area) => {
                        area.holes.forEach(hole => {
                            hole.stations.forEach(stationId => {
                                const reportStation = courseReportStations.find(r => r.stationId === stationId);
                                if (reportStation) {
                                    if (reportStation.isRunning) {
                                        const sta = new StationStatusStation({ ...reportStation, name: stations.find(s => s.id === stationId).name });
                                        acc.running.push(new StationStatusItem(area.name, hole.name, sta));
                                    }
                                    if (reportStation.totalDelta < duration('-00:00:01')) {
                                        const sta = new StationStatusStation({ ...reportStation, name: stations.find(s => s.id === stationId).name });
                                        acc.ranUnder.push(new StationStatusItem(area.name, hole.name, sta));
                                    }
                                }
                            });
                        });
                        return acc;
                    }, { running: [], ranUnder: [] });

                    return new StationStatuses(running, ranUnder);
                }),
                catchError(err => {
                    // TODO: Do some intelligent error handling as deemed necessary.
                    throw err;
                })
            )
    }

    public requestSpatialAdjustData(courseItem: LynxCloudCourseItem = null) {
        if (courseItem != null) this.spatialAdjustCourseItem = courseItem;
        if (this.spatialAdjustCourseItem == null) return;

        courseItem = this.spatialAdjustCourseItem;

        this.lynxStations = [];
        this.lynxPrograms = [];
        this.lynxPercentAdjustments = [];

        this.requestStations(courseItem);
        this.requestProgramTopo(courseItem);
        this.requestPercentAdjustments(courseItem);
    }

    public get StationAdjustList(): SaStationAdjustItem[] {
        return this.stationAdjustList;
    }

    public requestChangePercentAdjust(entityType: SpatialAdjustEntityType = SpatialAdjustEntityType.ProgramStation, percentAdjust: number, ids: number[]): void {
        if (this.spatialAdjustCourseItem == null) throw new Error("LynxCloud course information is not set.");

        const message = `subject: change-pct-adjust\nmessage-id: ${this.genericMessageId}\nclient-id: ${this.clientId}\ncontent-type: application/json\ncontent:\n{\n\t"Ids": [${ids}],\n"EntityType": ${entityType},\n"PercentAdjust": ${percentAdjust}\n}`
        const commandTopic = `${this.spatialAdjustCourseItem.installationId}/server/command`;

        this.mqttService.publish(commandTopic, message)
            .subscribe({
                next: _ => {
                    console.log();
                },
                error: err => setTimeout(() => {
                    this.requestChangePercentAdjust(entityType, percentAdjust, ids);
                }, 5000)
            })
    }

    // public requestChangePercentAdjust(courseItem: LynxCloudCourseItem, entityType: number = 7, percentAdjust: number = 132, ids: number[] = [5674]): void {
    //     const message = `subject: change-pct-adjust\nmessage-id: ${this.genericMessageId}\nclient-id: ${this.clientId}\ncontent-type: application/json\ncontent:\n{\n\t"Ids": [${ids}],\n"EntityType": ${entityType},\n"PercentAdjust": ${percentAdjust}\n}`
    //     const commandTopic = `${courseItem.installationId}/server/command`;
    //
    //     this.mqttService.publish(commandTopic, message)
    //         .subscribe({
    //             next: _ => { },
    //             error: err => setTimeout(() => {
    //                 this.requestChangePercentAdjust(courseItem, entityType, percentAdjust, ids);
    //             }, 5000)
    //         })
    // }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private checkSpatialAdjustDataStatus() {
        if (this.lynxStations.length < 1 || this.lynxPrograms.length < 1 || this.lynxPercentAdjustments.length < 1) { return; }

        this.createStationAdjustDictionary();
    }

    private createStationAdjustDictionary() {
        if (this.lynxStations.length < 1 || this.lynxPrograms.length < 1 || this.lynxPercentAdjustments.length < 1) { return; }

        this.stationAdjustDict = {};

        this.lynxStations.forEach(sta => {
            const programs = this.lynxPrograms.filter(p => p.holes.some(h => h.stations.some(s => s.stationId == sta.id)));

            this.stationAdjustDict[sta.name] = [];

            programs.forEach(p => {
                const station = p.holes.flatMap(h => h.stations).find(s => s.stationId === sta.id);
                const adjustment = this.lynxPercentAdjustments.find(a => a.id === station.id);

                this.stationAdjustDict[sta.name].push(new StationAdjustInfo({
                    programId: p.id,
                    programName: p.name,
                    stationId: sta.id,
                    adjustment: adjustment,
                }));
            });
        });

        this.spacialAdjustDataReady.next(this.stationAdjustDict);

        this.createStationAdjustList(this.stationAdjustDict);
    }

    private createStationAdjustList(stationAdjustDict: StationAdjustDict) {
        this.stationAdjustList = Object.entries(stationAdjustDict)
            .map(([key, data]) => {
                if (data == null || data.length < 1) return;

                return new SaStationAdjustItem({
                    stationName: key,
                    percentAdjustPercent: data[0].adjustment.basePercentAdjust,
                    stationAdjustId: data[0].adjustment.id
                });
            });

        this.stationAdjustListUpdated.next(this.stationAdjustList);
    }

    private requestCoursesList(): void {
        const message = `subject: get-courses\nmessage-id: ${this.genericMessageId}\nclient-id: ${this.clientId}\ncontent-type: application/json\ncontent:\n{\n}`

        this.installationIds.forEach(id => {
            const commandTopic = `${id}/server/command`;

            this.mqttService.publish(commandTopic, message)
                .subscribe({
                    next: _ => {
                        console.log();
                    },
                    error: err => setTimeout(() => {
                        this.requestCoursesList()
                    }, 5000)
                })

        })
    }

    private requestGeoTopography(courseItem: LynxCloudCourseItem): void {
        const message = `subject: get-geo-topo\nmessage-id: ${this.genericMessageId}\nclient-id: ${this.clientId}\ncontent-type: application/json\ncontent:\n{\n\t"Id": ${courseItem.courseId}\n}`
        const commandTopic = `${courseItem.installationId}/server/command`;

        this.mqttService.publish(commandTopic, message)
            .subscribe({
                next: _ => { },
                error: err => setTimeout(() => this.requestGeoTopography(courseItem), 5000)
            })
    }

    private requestProgramTopo(courseItem: LynxCloudCourseItem): void {
        const message = `subject: get-program-topo\nmessage-id: ${this.genericMessageId}\nclient-id: ${this.clientId}\ncontent-type: application/json\ncontent:\n{\n\t"Id": ${courseItem.courseId}\n}`
        const commandTopic = `${courseItem.installationId}/server/command`;

        this.mqttService.publish(commandTopic, message)
            .subscribe({
                next: _ => { },
                error: err => setTimeout(() => this.requestGeoTopography(courseItem), 5000)
            })
    }

    private requestCourseReport(courseItem: LynxCloudCourseItem): void {
        const message = `subject: get-course-report\nmessage-id: ${this.genericMessageId}\nclient-id: ${this.clientId}\ncontent-type: application/json\ncontent:\n{\n\t"Id": ${courseItem.courseId}\n}`
        const commandTopic = `${courseItem.installationId}/server/command`;

        this.mqttService.publish(commandTopic, message)
            .subscribe({
                next: _ => { },
                error: err => setTimeout(() => this.requestCourseReport(courseItem), 5000)
            })
    }

    private requestStations(courseItem: LynxCloudCourseItem): void {
        const message = `subject: get-stations\nmessage-id: ${this.genericMessageId}\nclient-id: ${this.clientId}\ncontent-type: application/json\ncontent:\n{\n\t"Id": ${courseItem.courseId}\n}`
        const commandTopic = `${courseItem.installationId}/server/command`;

        this.mqttService.publish(commandTopic, message)
            .subscribe({
                next: _ => { },
                error: err => setTimeout(() => this.requestStations(courseItem), 5000)
            })
    }

    private requestPercentAdjustments(courseItem: LynxCloudCourseItem): void {
        const message = `subject: get-percent-adjustments\nmessage-id: ${this.genericMessageId}\nclient-id: ${this.clientId}\ncontent-type: application/json\ncontent:\n{\n\t"Id": ${courseItem.courseId}\n}`
        const commandTopic = `${courseItem.installationId}/server/command`;

        this.mqttService.publish(commandTopic, message)
            .subscribe({
                next: _ => { },
                error: err => setTimeout(() => {
                    this.requestPercentAdjustments(courseItem)
                }, 5000)
            })
    }

    // private requestChangePercentAdjustDuration(courseItem: LynxCloudCourseItem): void {
    //     const message = `subject: change-pct-adjust-duration\nmessage-id: ${this.genericMessageId}\nclient-id: ${this.clientId}\ncontent-type: application/json\ncontent:\n{\n\t"Id": ${courseItem.courseId}\n}`
    //     const commandTopic = `${courseItem.installationId}/server/command`;
    //
    //     this.mqttService.publish(commandTopic, message)
    //         .subscribe({
    //             next: _ => { },
    //             error: err => setTimeout(() => {
    //                 this.requestChangePercentAdjustDuration(courseItem)
    //             }, 5000)
    //         })
    // }

    private setupMqttListeners() {
        this.mqttService.state
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (state: MqttConnectionState) => {
                    switch (state) {
                        case MqttConnectionState.CONNECTING:
                            break;
                        case MqttConnectionState.CONNECTED:
                            this.isPendingInitialization = false;
                            this.isInitialized = true;
                            this.mqttBrokerConnected();
                            break;
                        case MqttConnectionState.CLOSED:
                            break;
                    }
                }
            })
    }

    private mqttBrokerConnected() {
        this.installationIds.forEach(id => {

            const responseTopic = `${id}/server/response/${this.clientId}`;

            this.mqttService.observe(responseTopic)
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: (message: IMqttMessage) => {
                        const payload = this.parseGenericMessage(message.payload.toString())

                        switch (payload.subject) {
                            case 'get-course-report':
                                const courseReport = new GetCourseReportMessage(payload);
                                this.courseReportRetrieved.next(courseReport.content.result)
                                break;
                            case 'get-courses':
                                const installationId = message.topic.split('/')[0];
                                const courses = new GetCoursesMessage({ ...payload, installationId });
                                this.courseListRetrieved.next(courses.content.result);
                                break;
                            case 'get-stations':
                                const stations = new GetStationsMessage(payload);
                                this.lynxStations = stations.content.result;
                                this.stationsRetrieved.next(stations.content.result);
                                this.checkSpatialAdjustDataStatus();
                                break;
                            case 'get-hardware-topo':
                                break;
                            case 'get-geo-topo':
                                const topography = new GetGeoTopoMessage(payload);
                                this.geoTopographyRetrieved.next(topography.content.result)
                                break;
                            case 'get-program-topo':
                                const topo = new GetProgramTopoMessage(payload);
                                this.lynxPrograms = topo.content.result;
                                this.checkSpatialAdjustDataStatus();
                                // console.log(this.lynxPrograms.length);
                                // const x = topo.content.result
                                //         .flatMap(area => area.holes)
                                //         .flatMap(hole => hole.stations)
                                //         .filter(station => station.stationId === 222);
                                // console.log(x);
                                break;

                            case 'get-switches':
                                break;

                            case 'get-percent-adjustments':
                                this.lynxPercentAdjustments = payload.content.result.map(r => new LynxPercentAdjust(r));
                                this.lynxPercentAdjustments.sort((a, b) => a.id - b.id);
                                this.checkSpatialAdjustDataStatus();
                                break;

                            case 'change-pct-adjust-duration':
                                const durs = payload.content.result;
                                console.log(durs?.length, durs?.length);
                                break;

                            case 'change-pct-adjust':
                                // const result = payload.content?.result;
                                if (this.spatialAdjustCourseItem != null) {
                                    // Request Spatial Adjust Data from Lynx Cloud to get updated Current Adjustment
                                    // This will validate that our adjustment was successful or not.
                                    this.requestSpatialAdjustData(this.spatialAdjustCourseItem);
                                }
                                break;
                            default:
                                break;
                        }
                    }
                });

        })
    }

    private parseGenericMessage(payload: string): GenericMqttMessage {
        const payloadPart = payload.split('\n');

        return new GenericMqttMessage({
            subject: payloadPart[0].replace('subject: ', ''),
            messageId: payloadPart[1].replace('message-id: ', ''),
            clientId: payloadPart[2].replace('client-id: ', ''),
            contentType: payloadPart[3].replace('content-type: ', ''),
            content: payloadPart[4].replace('content: ', '')
        })
    }
}
