<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [showEmptyFooter]="isGridsterInMobileMode"
            [isMiniModeWidget]="true"
            [(alertText)]="alertText"
    >

        <div class="toro-flex-child mini-mode-widget-container">
            <div id="mini-notes-editor" (click)="onLaunchModalWidget()">
                <ng-container [ngTemplateOutlet]="quillEditor"></ng-container>
            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
    >

        <div id="id-notes-container" class="toro-flex-child toro-widget-content-pane" [ngClass]="{'fade-on-resize': isResizing}" >
            <ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="quillEditor"></ng-container>
            <ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="quillEditor2"></ng-container>

            <toro-waiting-indicator *ngIf="isSavingData"></toro-waiting-indicator>
        </div>

        <div id="id-note-bottom-tb">
            <p-button type="button" [label]="'STRINGS.CANCEL' | translate" class="ui-button-secondary" (onClick)="onCancel()"></p-button>
            <p-button type="button" [label]="'STRINGS.SAVE' | translate" [disabled]="isBusy || !isDirty" (onClick)="onSave()"></p-button>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <fa-icon [icon]="['far', 'list-alt']" ></fa-icon>
</ng-template>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': WidgetDoubleRowHeight + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>

<ng-template #quillEditor>
    <div id="editor-container" [ngStyle]="{'height': editorContainerHeight}"></div>
</ng-template>

<ng-template #quillEditor2 style="">
    <div id="editor-container2"></div>
</ng-template>
