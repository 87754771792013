import WizardIntegration = ToroEnums.WizardIntegration;
import WidgetType = ToroEnums.WidgetType;

import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BroadcastService } from '../../../../../common/services/broadcast.service';
import { DashUserInfo } from '../../../../../api/dash-user/models/dash-user-info.model';
import { DashUserManagerService } from '../../../../../api/dash-user/dash-user-manager.service';
import { SpectrumManagerService } from '../../../../../api/spectrum/spectrum-manager.service';
import { ToroEnums } from '../../../../../common/enumerations/toro.enums';
import { WizardSettings } from '../../models/wizard-settings.model';

@UntilDestroy()
@Component({
  selector: 'toro-spectrum-integration',
  templateUrl: './spectrum-integration.component.html',
  styleUrls: ['./spectrum-integration.component.less']
})
export class SpectrumIntegrationComponent implements OnInit {
  @HostBinding('class') class = 'wiz-integration-component perry-weather-integration';

  @Output() canConnect = new EventEmitter<boolean>();
  @Output() configured = new EventEmitter<WizardIntegration>();
  @Output() settingsChange = new EventEmitter<WizardSettings>();

  @Input() settings: WizardSettings;

  readonly stepsCount = 1;
  private elements: any;

  isBusy = false;
  showError = false;
  spinnerText = 'CASE_SENSITIVE.VERIFYING_API_KEY'
  apiKey: string;

  // =========================================================================================================================================================
  // C'tor and Lifecycle Hooks
  // =========================================================================================================================================================

  constructor(private broadcastService: BroadcastService,
              private dashUserManager: DashUserManagerService,
              private spectrumManager: SpectrumManagerService,
  ) { }

  ngOnInit(): void {
    this.broadcastService.setupDoConnect
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.verifyConfiguration();
        });

    // We want to modify the behavior of the p-inputNumber component to allow us to assess the controls
    // value after each key press. To do that, we will search the dom for our unique container component
    // and then find out "common" p-inputNumber class element. There will only be one in our container.
    // Once we have our element, we will assign the keyup event handler.
    const container = document.getElementsByClassName('wic-perry-weather-info');
    this.elements = container[0].getElementsByClassName('perry-weather-integration-key');

    if (this.elements.length != 1) throw new Error('There should be one and only one input element here.');
    this.elements[0].addEventListener('keyup', this.keyUp.bind(this))

    if (this.settings && this.settings.spectrumApiKey != null) {
      this.apiKey = this.settings.spectrumApiKey;
      this.canConnect.next(true);
    }
  }

  ngOnDestroy() {
    this.elements[0].removeEventListener('keyup', this.keyUp.bind(this))
    this.canConnect.emit(false);
  }

  // =========================================================================================================================================================
  // Event Handler
  // =========================================================================================================================================================

  private keyUp(event) {
    const maskless = event.target.value.replace('_', '');
    this.canConnect.emit(maskless.length == 32);
  }

  onPasteApiKey(event: ClipboardEvent) {
    const pastedText = event?.clipboardData?.getData('text');
    this.canConnect.emit(pastedText && pastedText.length == 32);
  }

  onCutApiKey(event: ClipboardEvent) {
    const pastedText = event?.clipboardData?.getData('text');
    this.canConnect.emit(pastedText && pastedText.length == 32);
  }

  // =========================================================================================================================================================
  // Helper Methods
  // =========================================================================================================================================================

  private verifyConfiguration() {
    this.showError = false;
    this.isBusy = true;
    let dashUser: DashUserInfo;

    const sources: Observable<any>[] = [
      this.dashUserManager.getDashUserInfo().pipe(take(1)),
      this.spectrumManager.getFieldScoutCollections(this.apiKey).pipe(take(1)),
    ];

    forkJoin(sources)
        .pipe(finalize(() => this.isBusy = false))
        .subscribe({
          next: ([user, collections]) => {
            dashUser = user;

            this.broadcastService.setupToggleWidgetState.next({ type: WidgetType.SpectrumMoisture, isEnabled: true });

            const userPrefs = dashUser.preferences;
            userPrefs.spectrumApiKey = this.apiKey;
            this.dashUserManager.updateDashUserPreferences(userPrefs)
                .subscribe(() => {
                  this.settings.spectrumApiKey = this.apiKey;
                  this.settingsChange.next(this.settings);
                  this.configured.next(WizardIntegration.Spectrum);

                  // Notify Spectrum Widget. This will only matter if the Spectrum widget is already being displayed.
                  this.broadcastService.setupSpectrumApiKeyChanged.next(this.apiKey);
                })
          },
          error: err => {
            const error = err?.error?.Error_1[0];
            if (error && error.toLowerCase().includes('invalid api key')) {
              this.showError = true;
            }
          }
        })

  }
}
