
export class SaStationAdjustItem {

    constructor(json: any = null) {
        if (json) {
            Object.assign(this, json);
        }
    }

    stationName: string
    percentAdjustPercent: number;
    stationAdjustId: number;

}
