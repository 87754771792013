import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../../common/services/analytics.service';
import { AuthManagerService } from '../../../../api/auth/auth-manager.service';
import { BroadcastService } from '../../../../common/services/broadcast.service';
import { DashAuthenticatedUser } from '../../../../api/auth/models/dash-authenticated-user.model';
import { DashUserManagerService } from '../../../../api/dash-user/dash-user-manager.service';
import { DeviceManagerService } from '../../../../common/services/device-manager.service';
import { finalize } from 'rxjs/operators';
import { Note } from '../../../../api/note/models/note.model';
import { NoteManagerService } from '../../../../api/note/note-manager.service';
import Quill from 'quill';
import { ToroAnalyticsEnums } from '../../../../common/enumerations/analytics.enums';
import { ToroDashboardWidget } from '../toro-dashboard-widget';
import { ToroGridsterWidget } from '../toro-gridster-widget';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'toro-widget-notes',
    templateUrl: './widget-notes.component.html',
    styleUrls: ['./widget-notes.component.less']
})
export class WidgetNotesComponent extends ToroDashboardWidget implements OnInit, AfterViewInit {
    iconColor = '#3178c6';
    title = 'WIDGET.NOTES';

    /**
     * In mini-mode, we need two quillEditors because the original one is still active on the underlying
     * mini-widget. We need a second one for the mini-modal.
     *
     * quillEditor is used on the desktop and the mini-mode read-only component.
     * quillEditor2 is used by the mini-modal
     *
     * NOTE: We are using the Quill editor directly as the primeNg pEditor had some keyboard issues on mobile devices.
     */
    protected quillEditor!: Quill;
    protected quillEditor2!: Quill;

    protected notesText: string;
    protected isDirty = false;
    protected isSavingData = false;
    protected editorContainerHeight: string;
    protected editor2ContainerHeight: string;

    private previousNotesText: string;
    private dashUser: DashAuthenticatedUser;

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(protected analyticsService: AnalyticsService,
                private authManager: AuthManagerService,
                protected broadcastService: BroadcastService,
                protected dashUserManager: DashUserManagerService,
                protected deviceManager: DeviceManagerService,
                private noteManager: NoteManagerService,
                protected translateService: TranslateService,
    ) {
        super(analyticsService, broadcastService, dashUserManager, deviceManager, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.dashUser = this.authManager.dashAuthenticatedUser;
    }

    ngAfterViewInit(): void {
        this.setupQuillEditor();

        if (this.isGridsterInMobileMode) {
            const toolbar = document.querySelector('.ql-toolbar') as HTMLElement;
            if (toolbar) {
                toolbar.style.display = 'none';
            }
        }

        this.setEditorContainerHeights();
    }

    // =========================================================================================================================================================
    // Base Class Overrides
    // =========================================================================================================================================================

    public get analyticsWidgetName(): string {
        return AnalyticsEvent.NotesWidgetName;
    }

    protected getWidgetData(isManualRefresh = false) {
        // Don't get new data if we have changes pending.
        // NOTE: If they user is making changes in two places at the same time, the changes made last will win and changes made on "the other device"
        // will be lost.
        if (!isManualRefresh && this.isDirty) return;

        if (isManualRefresh) { this.isBusy = true; }

        this.noteManager.getNote(this.dashUser.sid, this.dashUser.siteId)
            .pipe(finalize(() => this.isBusy = false))
            .subscribe({
                next: (note: Note) => {
                    this.notesText = note.text;

                    this.quillEditor.clipboard.dangerouslyPasteHTML(this.notesText);

                    // Get the text that the editor has. It does a bit of clean up.
                    this.notesText = this.quillEditor.root.innerHTML;
                    this.previousNotesText = this.notesText;
                    this.isDirty = false;

                    this.lastUpdateTimestamp = new Date();
                },
                error: err => {
                    console.log();
                }
            })
    }

    protected widgetResized(item: ToroGridsterWidget) {
        super.widgetResized(item);

        if (!this.isGridsterInMobileMode) {
            this.setEditorContainerHeights();
        }
    }

    protected onLaunchModalWidget() {
        super.onLaunchModalWidget();

        setTimeout(() => {
            const content = this.quillEditor.getContents();

            this.setupQuillEditor2();
            this.quillEditor2.setContents(content);

            this.quillEditor2.focus();
        });
    }

    // =========================================================================================================================================================
    // Event Handlers
    // =========================================================================================================================================================

    onCancel() {
        if (this.isGridsterInMobileMode) {
            this.quillEditor2.clipboard.dangerouslyPasteHTML(this.previousNotesText);
        }

        this.getWidgetData(true);
    }

    onSave() {
        this.saveText();

        // Update the read-only editor with the contents from changes made via quillEditor2
        if (this.isGridsterInMobileMode) {
            this.quillEditor.clipboard.dangerouslyPasteHTML(this.notesText);
        }
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private saveText() {
        this.isSavingData = true;

        // Place in setTimeout to give waiting indicator a chance to display; providing feedback to the user on save action.
        setTimeout(() => {

            // FB-12693: The underlying notes component requires urls to start with http(s)://. If they don't, the widget assumes they are relative and will fail.
            //           Here we attempt to append http:// where required.
            this.notesText = this.notesText.replace(/a href="(?!http)/g, 'a href="http://');

            const note = new Note({
                userId: this.dashUser.sid,
                siteId: this.dashUser.siteId,
                text: this.getCleanedUpText(this.notesText)
            });

            this.noteManager.updateNote(note)
                .pipe(finalize(() => this.isSavingData = false))
                .subscribe({
                    next: () => {
                        this.previousNotesText = this.notesText;
                        this.isDirty = false;

                        if (!this.isGridsterInMobileMode) {
                            this.quillEditor.clipboard.dangerouslyPasteHTML(this.notesText);
                        } else {
                            this.quillEditor2.clipboard.dangerouslyPasteHTML(this.notesText);
                        }
                    },
                    error: err => {
                        console.log();
                    }
                })
        }, 500)
    }

    private setupQuillEditor(): void {
        this.quillEditor = new Quill('#editor-container', {
            theme: 'snow', // Use the 'snow' theme or any other Quill theme
            modules: {
                toolbar: [
                    [{ 'size': ['small', false, 'large', 'huge'] }, { 'font': [] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'color': [] }, { 'background': [] }],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'align': [] }],
                    ['link', 'image', 'code'],
                    ['clean']
                ]
            }
        });

        this.quillEditor.on('text-change', () => {
            this.notesText = this.quillEditor.root.innerHTML;
            setTimeout(() => this.isDirty = this.previousNotesText != this.notesText);
        });

        console.log(this.previousNotesText);
    }

    private setupQuillEditor2(): void {
        this.quillEditor2 = new Quill('#editor-container2', {
            theme: 'snow', // Use the 'snow' theme or any other Quill theme
            modules: {
                toolbar: [
                    [{ 'size': ['small', false, 'large', 'huge'] }, { 'font': [] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'color': [] }, { 'background': [] }],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'align': [] }],
                    ['link', 'image', 'code'],
                    ['clean']
                ]
            }
        });

        this.quillEditor2.on('text-change', () => {
            this.notesText = this.quillEditor2.root.innerHTML;
            setTimeout(() => this.isDirty = this.previousNotesText != this.notesText);
        });

    }

    private setEditorContainerHeights(): void {
        setTimeout(() => {
            this.editorContainerHeight = this.itemCols == 2 ? 'calc(100% - 45px)' : 'calc(100% - 92px)';

            if (this.isGridsterInMobileMode) {
                this.editorContainerHeight = '100%';
            }
        })
    }

    private getCleanedUpText(text: string): string {
        return text;
        // return text.replace(new RegExp('<p><br></p>', 'g'), "");
    }
}
