<ng-container *ngIf="!isGridsterInMobileMode" [ngTemplateOutlet]="standardView"></ng-container>
<ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="miniModeView"></ng-container>

<ng-template #miniModeView>
    <toro-dashboard-widget-container
            [title]="title | translate"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [showLinkButton]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [menuStyleClass]="'toro-widget-menu spec-context-menu'"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            [isMiniModeWidget]="true"
            (gotoSite)="onLinkClick()"
    >
        <div class="toro-flex-child mini-mode-widget-container toro-component-shield-host">

            <div class="toro-widget-content-pane spec-mini-widget">
                <ng-container [ngTemplateOutlet]="specSelectionToolbar" [ngTemplateOutletContext]="{isMiniMode: true, isFreeFormCourse: isFreeFormCourse}"></ng-container>
                <toro-spec-range-selector [rangeCounts]="rangeCounts" (shieldClick)="onShowMiniModal()"></toro-spec-range-selector>
            </div>

        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #standardView>
    <toro-dashboard-widget-container
            [title]="title | translate"
            [titleIcon]="titleIcon"
            [showTitleIcon]="true"
            [showLinkButton]="true"
            [isBusy]="isBusy"
            [lastUpdated]="lastUpdated"
            [associatedWidget]="associatedWidget"
            [widgetMenuItems]="widgetMenuItems"
            [menuStyleClass]="'toro-widget-menu spec-context-menu'"
            [analyticsWidgetName]="analyticsWidgetName"
            [(alertText)]="alertText"
            [(isUnableToFetchData)]="isUnableToFetchData"
            [unableToFetchDataReason]="unableToFetchDataReason"
            (gotoSite)="onLinkClick()"
    >

        <div class="toro-flex-child" id="spec-widget-content">
            <div *ngIf="displayCols == 1" id="spec-grid" class="toro-widget-content-pane one-col" [ngClass]="{'fade-on-resize': isResizing && !isBusy}">
                <ng-container *ngIf="!isFreeFormCourse" [ngTemplateOutlet]="specGrid"></ng-container>
                <ng-container *ngIf="isFreeFormCourse" [ngTemplateOutlet]="specTable"></ng-container>
            </div>

            <div *ngIf="displayCols > 1" class="toro-widget-content-pane two-col" [ngClass]="{'fade-on-resize': isResizing && !isBusy}">
                <div class="toro-flex-child" id="spec-left-pane">
                    <ng-container *ngIf="!isFreeFormCourse" [ngTemplateOutlet]="specGrid"></ng-container>
                    <ng-container *ngIf="isFreeFormCourse" [ngTemplateOutlet]="specTable"></ng-container>
                </div>
                <ng-container [ngTemplateOutlet]="specMap"></ng-container>

                <div *ngIf="mapOptions != null" id="spec-show-modal-btn" [ngClass]="{'free-form': isFreeFormCourse}" (click)="showDesktopModal = true;">
                    <fa-icon icon="expand-alt"></fa-icon>
                </div>
            </div>
        </div>

    </toro-dashboard-widget-container>
</ng-template>

<ng-template #titleIcon>
    <img id="spectrum-title-icon" src="../../../../../../assets/images/spectrum-logo.png" alt="">
</ng-template>

<ng-template #specSelectionToolbar let-isMiniMode="isMiniMode" let-isFreeFormCourse='isFreeFormCourse' let-isMiniModal='isMiniModal'>
    <div id="spec-toolbar">
        <p-dropdown
                *ngIf="!isMiniModal"
                class="spec-dropdown"
                appendTo="body"
                [placeholder]="'CASE_SENSITIVE.SELECT_COURSE' | translate | titlecase"
                [options]="courseListItems"
                [ngClass]="{'is-free-form': isFreeFormCourse}"
                [(ngModel)]="selectedCourseName"
                (onChange)="onCourseChange($event)"
        ></p-dropdown>
        <p-dropdown
                *ngIf="(!isMiniMode && !isFreeFormCourse) || isMiniModal"
                class="spec-dropdown"
                [placeholder]="'STRINGS.SELECT_AREA' | translate | titlecase"
                [options]="areaListItems"
                [(ngModel)]="selectedAreaValue"
                [disabled]="areaListItems == null || areaListItems.length < 1"
                (onChange)="onSubCollectionChange($event)"
        ></p-dropdown>

    </div>
</ng-template>

<ng-template #specHoleSummary>
    <div class="spec-scan-timestamp prevent-select" [ngClass]="{'free-form': isFreeFormCourse}">
        <span>{{ 'STRINGS.SCAN' | translate }} <span class="scan-time">{{ selectedSessionTime }}</span></span>
        <span id="hole-avg-moisture">{{ 'CASE_SENSITIVE.AVERAGE_MOISTURE' | translate }}: <div id="value">{{holeAvgMoistureString}}</div></span>
    </div>
</ng-template>

<ng-template #specTable>
    <div id="sec-table-wrapper">
        <ng-container
                [ngTemplateOutlet]="specSelectionToolbar"
                [ngTemplateOutletContext]="{isFreeFormCourse: true}"
        ></ng-container>

        <ng-container *ngIf="isGridsterInMobileMode" [ngTemplateOutlet]="specHoleSummary"></ng-container>

        <toro-spec-range-selector
                [rangeCounts]="rangeCounts"
                [allowSelection]="true"
                [(selectedIndex)]="selectedRangeIndex"
        ></toro-spec-range-selector>

        <div id="spec-table-container">
            <div id="spec-table-header">
                <div>{{ 'STRINGS.AREA' | translate }}</div>
                <div>{{'STRINGS.AVERAGE' | translate}}</div>
                <div>{{'STRINGS.LOW' | translate}}</div>
            </div>

            <div id="spec-table-scroll-container" [ngClass]="{'no-data': filteredSubCollection?.length < 1}">
                <toro-spec-table-card
                        *ngFor="let sc of filteredSubCollection"
                        [item]="sc"
                        [displayCols]="displayCols"
                        [modalClosed]="showDesktopModal == false"
                        [(selectedItem)]="selectedTableItem"
                        (showTooltip)="onShowTooltip($event)"
                        (cardClicked)="onTableRowClick($event)"
                ></toro-spec-table-card>

                <div *ngIf="filteredSubCollection?.length < 1">{{ 'STRINGS.NO_DATA_TO_DISPLAY' | translate }}.</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #specGrid>
    <ng-container [ngTemplateOutlet]="specSelectionToolbar"></ng-container>

    <ng-container [ngTemplateOutlet]="specHoleSummary"></ng-container>
    <div #gridContainer id="spec-grid-wrapper" >
        <div *ngIf="gridData?.gridDefinition" class="spec-grid-container">
            <toro-spec-grid-card
                    *ngFor="let item of positionedGridItemData; let i = index"
                    [width]="gridItemWidth"
                    [height]="gridItemHeight"
                    [item]="item"
                    [colorPalette]="gridPalette"
                    [(selectedItem)]="selectedGridItem"
            ></toro-spec-grid-card>
        </div>
        <div id="spec-no-data" *ngIf="isDataLoadedAndSet && gridData?.gridDefinition == null">
            {{ 'SPECIAL_MSG.NO_DATA_IN_LAST_N_DAYS' | translate: { dateRangeDays: dateRangeDays } }}
        </div>
    </div>
</ng-template>

<ng-template #specMap>
    <div class="toro-flex-child" id="spec-right-pane" [ngClass]="{'no-map': mapOptions == null && gridItemData?.length < 1}">
        <div id="map-container" *ngIf="mapOptions != null && gridItemData?.length > 0" [ngClass]="{'fade-in': showMap, 'flex': isFreeFormCourse}" style="flex-direction: column;">
            <ng-container *ngIf="isFreeFormCourse" [ngTemplateOutlet]="specHoleSummary"></ng-container>

            <p-gmap [options]="mapOptions"
                    [overlays]="mapOverlays"
                    [style]="{'width':'100%', 'height':'100%'}"
                    (onMapReady)="onMapReady($event)"
            ></p-gmap>
        </div>
        <div *ngIf="isNoMap">{{ 'STRINGS.NO_MAP_DATA_TO_DISPLAY' | translate }}.</div>
        <toro-waiting-indicator *ngIf="mapOptions == null && gridItemData?.length > 0" [isVisible]="mapOptions == null"></toro-waiting-indicator>
    </div>
</ng-template>

<toro-mini-mode-widget-modal *ngIf="showMiniModeModal" (cancel)="showMiniModeModal = false">
    <div id="mini-mode-modal-content-container" [ngStyle]="{'height': (isFreeFormCourse ? WidgetDoubleRowHeight : WidgetSingleRowHeight) + 'px'}">
        <ng-container [ngTemplateOutlet]="standardView"></ng-container>
    </div>
</toro-mini-mode-widget-modal>

<p-dialog [visible]="isApiKeyDialogDisplayed" appendTo="body" [modal]="true" [resizable]="false" [dismissableMask]="true" [closable]="false" position="center" [style]="{maxWidth: '95%'}">
    <p-header>{{ 'CASE_SENSITIVE.SET_API_KEY' | translate | titlecase }}</p-header>

    <div id="api-key-dialog-container">
        <span>{{ 'CASE_SENSITIVE.SPECTRUM_API_KEY' | translate | titlecase }}</span>
        <input type="text" pInputText
               [(ngModel)]="newApiKey"
               [size]="30"
               [placeholder]="'CASE_SENSITIVE.ENTER_API_KEY' | translate | titlecase"
        />
    </div>

    <p-footer>
        <!-- Buttons will be laid out from right to left. First button listed will be at far right. -->
        <p-button type="button" [label]="'STRINGS.CANCEL' | translate" class="ui-button-secondary" (onClick)="onCancelApiKeyDialog()"></p-button>
        <p-button type="button" [label]="'STRINGS.SAVE' | translate" (onClick)="onSetApiKey()"></p-button>
    </p-footer>
</p-dialog>

<toro-spec-modal
        *ngIf="showDesktopModal"
        [isMiniModal]="isGridsterInMobileMode"
        (gotoSite)="onLinkClick()"
        (cancel)="showDesktopModal = false;"
>
    <div id="spec-modal-content-container" [ngClass]="{'mini-modal': isGridsterInMobileMode}">

        <div id="spec-modal-left-pane" [ngClass]="{'free-form': isGridsterInMobileMode}">
            <ng-container *ngIf="!isFreeFormCourse || (isFreeFormCourse && isGridsterInMobileMode)"
                          [ngTemplateOutlet]="specSelectionToolbar"
                          [ngTemplateOutletContext]="{isMiniModal: isGridsterInMobileMode, isFreeFormCourse: isFreeFormCourse}"
            ></ng-container>
            <ng-container *ngIf="!isFreeFormCourse" [ngTemplateOutlet]="specHoleSummary"></ng-container>

            <div *ngIf="!isFreeFormCourse && !isGridsterInMobileMode" id="spec-modal-grid-wrapper">
                <div *ngFor="let item of positionedGridItemData; let i = index" id="spec-modal-grid-container"  [style]="'flex: 1 1 ' + modalGridItemBasis + '%;'">
                    <toro-spec-grid-card
                            [item]="item"
                            [colorPalette]="gridPalette"
                            [(selectedItem)]="selectedGridItem"
                    ></toro-spec-grid-card>
                </div>
            </div>

            <ng-container *ngIf="isFreeFormCourse && !isGridsterInMobileMode" [ngTemplateOutlet]="specTable"></ng-container>
        </div>

        <div id="spec-modal-right-pane" style="position: relative;">
            <ng-container [ngTemplateOutlet]="specMap"></ng-container>
        </div>
    </div>
</toro-spec-modal>

