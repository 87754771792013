<div id="spec-table-card-container" class="toro-component-shield-host">
    <div id="stc-name" class="stc-cell" [ngClass]="{'is-selected': isSelected}">
        <div id="stc-name-content">
            <div id="stc-name-container">
                <div id="stc-group-name">{{name}}</div>
            </div>
            <div><fa-icon icon="chevron-right"></fa-icon></div>
        </div>
        <toro-component-shield (shieldClick)="onClick()"></toro-component-shield>
    </div>
    <div id="stc-avg" class="f-c-c stc-cell">
        <span class="prevent-select">{{avg}}</span>
        <toro-component-shield (shieldClick)="onClick()"></toro-component-shield>
    </div>
    <div id="stc-low" class="f-c-c stc-cell" [style.background-color]="item.lowColor">
        <span class="prevent-select">{{low}}</span>
        <toro-component-shield (shieldClick)="onClick(true)"></toro-component-shield>
    </div>


</div>
