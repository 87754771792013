/* eslint-disable max-len */
import AnalyticsCategory = ToroAnalyticsEnums.AnalyticsCategory;
import AnalyticsEvent = ToroAnalyticsEnums.AnalyticsEvent;
import WidgetType = ToroEnums.WidgetType;

import { map, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '../../common/services/analytics.service';
import { AppInjector } from '../../demo/demo.module';
import { AuthManagerService } from '../auth/auth-manager.service';
import { BroadcastService } from '../../common/services/broadcast.service';
import { DashboardWidgetInfo } from './models/dashboard-widget-info.model';
import { DashMessageService } from '../../common/services/dash-message.service';
import { DemoModeMockDataService } from '../../demo/demo-mode-mock-data.service';
import { DeviceManagerService } from '../../common/services/device-manager.service';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { ToroAnalyticsEnums } from '../../common/enumerations/analytics.enums';
import { ToroEnums } from '../../common/enumerations/toro.enums';
import { ToroGridsterWidget } from '../../core/dashboard/widgets/toro-gridster-widget';
import { TranslateService } from '@ngx-translate/core';
import { UpdateDashboardWidgetInfo } from './models/update-dashboard-widget-info.model';
import { UpdateWidgetModel } from './models/update-widget.model';
import { WidgetApiService } from './widget-api.service';
import { WidgetSelectItemValue } from './models/widget-select-item-value.model';

enum WidgetTrackingEvent {
    Load,
    Add,
    Remove
}

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class WidgetManagerService {
    private demoModeMockDataService: DemoModeMockDataService;

    // Subjects
    widgetCollectionChange = new Subject<{ addedWidgetTypes: WidgetType[], removedWidgetTypes: WidgetType[] }>();

    isMobileGridDisplay = false;

    // Non Expiring Cached Collections;
    private _dashboardWidgetsDict: { [deviceId: number]: DashboardWidgetInfo[] } = {};

    // =========================================================================================================================================================
    // C'tor and Lifecycle Hooks
    // =========================================================================================================================================================

    constructor(private analyticsService: AnalyticsService,
                private authManager: AuthManagerService,
                private broadcastService: BroadcastService,
                private dashMessageService: DashMessageService,
                private deviceManager: DeviceManagerService,
                private translateService: TranslateService,
                private widgetApiService: WidgetApiService,
    ) {
        if (environment.isDemoMode) { this.demoModeMockDataService = AppInjector.get(DemoModeMockDataService); }

        // Occurs when user makes a selection from the widget MultiSelect in the app header.
        this.broadcastService.widgetSelectionChange
            .pipe(untilDestroyed(this))
            .subscribe((selectedWidgets: WidgetSelectItemValue[]) => {
                this.updateWidgetSelection(selectedWidgets.map(item => item.type));
            });

        // Occurs when user clicks on a widget close button.
        this.broadcastService.removeWidget
            .pipe(untilDestroyed(this))
            .subscribe((widgetInfo: { widgetId: number, widgetTypeId: WidgetType }) => {
                const selectedWidgets = this._dashboardWidgetsDict[this.deviceId].filter(w => w.widgetType !== widgetInfo.widgetTypeId).map(w => w.widgetType);
                this.updateWidgetSelection(selectedWidgets);
            });
    }

    // =========================================================================================================================================================
    // Public Methods
    // =========================================================================================================================================================

    getDashboardWidgets(userId: string, siteId: number, deviceId?: string): Observable<DashboardWidgetInfo[]> {
        if (environment.isDemoMode) {
            this._dashboardWidgetsDict[deviceId] = this.demoModeMockDataService.dashboardWidgets;
            return of(this._dashboardWidgetsDict[deviceId]);
        }

        if (this._dashboardWidgetsDict[deviceId] && this._dashboardWidgetsDict[deviceId].length > 0) { return of(this._dashboardWidgetsDict[deviceId]); }

        return this.widgetApiService.getDashboardWidgets(userId, siteId, deviceId)
            .pipe(map((widgets: DashboardWidgetInfo[]) => {
                if (!widgets || widgets.length < 1) widgets = WidgetManagerService.defaultDashboardWidgets;

                // setTimeout(() => this.trackWidgetLoadAddRemove(widgets.map(w => w.widgetType), WidgetTrackingEvent.Load));

                return this._dashboardWidgetsDict[deviceId] = widgets;
            }));
    }

    saveDashboardWidgets(widgets: DashboardWidgetInfo[], deviceId: string): Observable<boolean> {
        const updateDashboardWidgetInfo = new UpdateDashboardWidgetInfo(this.authManager.dashAuthenticatedUser, widgets, deviceId);

        if (environment.isDemoMode) {
            this._dashboardWidgetsDict[deviceId] = widgets;
            return of(true);
        }

        return this.widgetApiService.saveDashboardWidgets(updateDashboardWidgetInfo)
            .pipe(tap(() => this._dashboardWidgetsDict[deviceId] = widgets));
    }

    getWidgetSelectList(): SelectItem[] {
        if (environment.isDemoMode) return this.demoModeMockDataService.getWidgetSelectList();

        const widgetSelectList: SelectItem[] = [
            // { label: 'Test', value: { type: ToroEnums.WidgetType.Test } },

            // { label: this.translateService.instant('WIDGET.DECISION_TREE'), value: { type: WidgetType.DecisionTree } },
            { label: this.translateService.instant('WIDGET.EZ_LOCATOR'), value: { type: WidgetType.EzLocator } },
            { label: this.translateService.instant('WIDGET.GCSAA'), value: { type: WidgetType.Gcsaa } },
            { label: this.translateService.instant('WIDGET.GCSAA_ALT'), value: { type: WidgetType.GcsaaAlt } },
            // { label: this.translateService.instant('WIDGET.LYNX_RUNNING_STATIONS'), value: { type: WidgetType.LynxRunningStations } },
            { label: this.translateService.instant('WIDGET.LYNX_CLOUD'), value: { type: ToroEnums.WidgetType.LynxStationStatuses } },
            { label: this.translateService.instant('WIDGET.LYNX_PUMP_STATIONS'), value: { type: WidgetType.LynxPumpStations } },
            { label: this.translateService.instant('WIDGET.LYNX_LOCAL_CURRENT_WEATHER'), value: { type: WidgetType.LynxLocalCurrentWeather } },
            { label: this.translateService.instant('WIDGET.LYNX_LOCAL_WEATHER_GRAPHS'), value: { type: WidgetType.LynxLocalWeatherGraphs } },
            { label: this.translateService.instant('WIDGET.MY_TURF_PARTS_ORDERS'), value: { type: WidgetType.MyTurfOrders } },
            { label: this.translateService.instant('WIDGET.MY_TURF_MAINTENANCE'), value: { type: WidgetType.MyTurfMaintenance } },
            { label: this.translateService.instant('WIDGET.NOTES'), value: { type: WidgetType.Notes } },
            { label: this.translateService.instant('WIDGET.TORO_NSN'), value: { type: WidgetType.Nsn } },
            { label: this.translateService.instant('WIDGET.PERRY_WEATHER'), value: { type: WidgetType.PerryWeather } },
            { label: this.translateService.instant('WIDGET.PLAYBOOKS'), value: { type: WidgetType.Playbooks } },
            { label: this.translateService.instant('WIDGET.POGO'), value: { type: WidgetType.Pogo } },
            { label: this.translateService.instant('WIDGET.SOIL_SCOUT_MOISTURE'), value: { type: ToroEnums.WidgetType.SoilScoutMoisture } },
            { label: this.translateService.instant('WIDGET.SOIL_SCOUT_SALINITY'), value: { type: ToroEnums.WidgetType.SoilScoutSalinity } },
            { label: this.translateService.instant('WIDGET.SOIL_SCOUT_TEMPERATURE'), value: { type: ToroEnums.WidgetType.SoilScoutTemperature } },
            { label: this.translateService.instant('WIDGET.SOIL_SCOUT_MAP'), value: { type: ToroEnums.WidgetType.SoilScoutMap } },
            { label: this.translateService.instant('WIDGET.SPECTRUM_MOISTURE'), value: { type: ToroEnums.WidgetType.SpectrumMoisture } },
            { label: this.translateService.instant('WIDGET.TASK_TRACKER_DAILY_MEASUREMENTS'), value: { type: WidgetType.TaskTrackerDailyMeasurements } },
            { label: this.translateService.instant('WIDGET.TASK_TRACKER_LABOR_SUMMARY'), value: { type: WidgetType.TaskTrackerLaborSummary } },
            { label: this.translateService.instant('WIDGET.TASK_TRACKER_SAFETY_AND_TRAINING'), value: { type: WidgetType.TaskTrackerSafety } },
            // { label: this.translateService.instant('WIDGET.TASK_TRACKER_TURF_RAD'), value: { type: WidgetType.TaskTrackerTurfRad } },
            // { label: this.translateService.instant('WIDGET.TRACKING'), value: { type: ToroEnums.WidgetType.EquipmentTracking } },
            // { label: this.translateService.instant('WIDGET.GEO_FENCING'), value: { type: ToroEnums.WidgetType.GeoFencing } },
            { label: this.translateService.instant('WIDGET.TURF_GUARD_MOISTURE'), value: { type: WidgetType.TurfGuardMoisture } },
            { label: this.translateService.instant('WIDGET.TURF_GUARD_SALINITY'), value: { type: WidgetType.TurfGuardSalinity } },
            { label: this.translateService.instant('WIDGET.TURF_GUARD_TEMPERATURE'), value: { type: WidgetType.TurfGuardTemperature } },
            { label: this.translateService.instant('WIDGET.EVAPOTRANSPIRATION'), value: { type: WidgetType.Evapotranspiration } },
            { label: this.translateService.instant('WIDGET.LOCAL_WEATHER'), value: { type: WidgetType.LocalWeatherForecast } },
            // { label: this.translateService.instant('WIDGET.LOCAL_DTN_WEATHER'), value: { type: WidgetType.DtnWeather } },
            { label: this.translateService.instant('WIDGET.FROST_WARNING'), value: { type: WidgetType.FrostWarning } },
            // { label: this.translateService.instant('WIDGET.WEATHER_AVERAGES'), value: { type: WidgetType.WeatherAverages } },
            { label: this.translateService.instant('WIDGET.WEATHER_RADAR'), value: { type: WidgetType.WeatherRadar } },
            { label: this.translateService.instant('WIDGET.WEATHER_GRAPHS'), value: { type: WidgetType.WeatherGraphs } },

            // { label: this.translateService.instant('WIDGET.GREEN_SIGHT'), value: { type: ToroEnums.WidgetType.GreenSight } },
            // { label: this.translateService.instant('WIDGET.TURF_CLOUD_COURSE_TRAKK'), value: { type: ToroEnums.WidgetType.TurfCloudCourseTrakk } },
            // { label: this.translateService.instant('WIDGET.TURF_CLOUD_LABOR_STATS'), value: { type: ToroEnums.WidgetType.TurfCloudLaborStats } },

            // TODO: TEMPORARY for development
            // { label: this.translateService.instant('WIDGET.LYNX_PUMP_STATION'), value: { type: ToroEnums.WidgetType.LynxPumpStation } },
            // { label: 'Lynx: Pump Stations w/Gauges', value: { type: ToroEnums.WidgetType.LynxPumpStationsWithGauges } },
        ];

        // Modify Widget Selection based on Desktop vs. Mobile
        const insertIndexForSoilScout = 15;
        if (this.deviceManager.isGridsterInMobileMode) {
            widgetSelectList.splice(insertIndexForSoilScout,3,
                { label: this.translateService.instant('WIDGET.SOIL_SCOUT'), value: { type: ToroEnums.WidgetType.SoilScoutMobile } }
            )
        }

        // // TODO: TEMPORARY for Decision Tree Widget
        // let isDecisionTreeWidgetRemoved = false
        // if (environment.name === 'toro-prod') {
        //     widgetSelectList.splice(0, 1);
        //     isDecisionTreeWidgetRemoved = true;
        // }
        //
        // if (environment.name !== 'toro-prod') {
        //     const insertionIndex = isDecisionTreeWidgetRemoved ? 11 : 12
        //     const tracking = { label: this.translateService.instant('WIDGET.TRACKING'), value: { type: ToroEnums.WidgetType.EquipmentTracking } };
        //     widgetSelectList.splice(insertionIndex, 0, tracking);
        // }

        // if (environment.name !== 'toro-beta') {
        //     widgetSelectList.push(
        //         { label: this.translateService.instant('WIDGET.GREEN_SIGHT'), value: { type: ToroEnums.WidgetType.GreenSight } },
        //         { label: this.translateService.instant('WIDGET.TURF_CLOUD_COURSE_TRAKK'), value: { type: ToroEnums.WidgetType.TurfCloudCourseTrakk } },
        //         { label: this.translateService.instant('WIDGET.TURF_CLOUD_LABOR_STATS'), value: { type: ToroEnums.WidgetType.TurfCloudLaborStats } }
        //     );
        // }

        return widgetSelectList;
    }

    makeWidget(widgetType: ToroEnums.WidgetType, dashboardWidgetInfo: DashboardWidgetInfo): ToroGridsterWidget {
        const widget = new ToroGridsterWidget(widgetType, dashboardWidgetInfo);

        // Create default 'data settings' config for widgets that require it. Don't for widgets that do not!
        if (!dashboardWidgetInfo && widgetType !== WidgetType.GreenSight) {
            widget.config = DashboardWidgetInfo.getDefaultConfig(widgetType);
        }

        switch (widgetType) {
            // 1x1 (rows x cols) Fixed
            case WidgetType.EquipmentTracking:
            case WidgetType.GeoFencing:
            case WidgetType.MyTurfOrders:
            case WidgetType.MyTurfMaintenance:
            case WidgetType.LynxPumpStation:
            case WidgetType.TaskTrackerDailyMeasurements:
            case WidgetType.TaskTrackerSafety:
            // case WidgetType.LynxPumpStations:
            // case WidgetType.LynxLocalCurrentWeather:
            case WidgetType.Nsn:
            case WidgetType.DecisionTree:
            case WidgetType.Gcsaa:
            case WidgetType.GcsaaAlt:
            case WidgetType.EzLocator:
            case WidgetType.WeatherAverages:
            case WidgetType.SoilScoutSalinity:
            case WidgetType.SoilScoutMoisture:
            case WidgetType.SoilScoutTemperature:
                widget.rows = widget.rows || 1;
                widget.cols = widget.cols || 1;
                widget.resizeEnabled = false;
                break;

            case WidgetType.LynxPumpStationsWithGauges:
                widget.rows = widget.rows || 1;
                widget.cols = widget.cols || 1;
                widget.maxItemCols = 5;                 // Set to max allowed. We will programmatically update based on number of stations.
                widget.maxItemRows = 1;
                break;

            case WidgetType.LynxLocalWeatherGraphs:
                widget.rows = widget.rows || 2;
                widget.cols = widget.cols || 1;
                widget.maxItemCols = 5;                 // Set to max allowed. We will programmatically update based on number of stations.
                widget.maxItemRows = 2;
                break;

            case WidgetType.LynxPumpStations:
            case WidgetType.LynxLocalCurrentWeather:
                widget.rows = widget.rows || 1;
                widget.cols = widget.cols || 1;
                widget.maxItemCols = 1;
                widget.maxItemRows = 4;                 // Set to max allowed. We will programmatically update based on number of stations.
                break;

            // 2x1 - Resizable Cols
            case WidgetType.Test:
            case WidgetType.LynxRunningStations:
            case WidgetType.FrostWarning:
            case WidgetType.Evapotranspiration:
            case WidgetType.LocalWeatherForecast:
            case WidgetType.DtnWeather:
            case WidgetType.PerryWeather:
            case WidgetType.Pogo:
            case WidgetType.SpectrumMoisture:
                widget.rows = widget.rows || 1;
                widget.cols = !this.isMobileGridDisplay ? widget.cols || 2 : 1;
                widget.maxItemCols = 2;
                widget.maxItemRows = 1;
                break;

            // 2x2 - Resizable Rows & Cols (starts 1x1)
            case WidgetType.Notes:
                widget.rows = widget.rows || 1;
                widget.cols = widget.cols || 1;
                widget.maxItemCols = 2;
                widget.maxItemRows = 2;
                break;

            // 3x1 - Resizable Cols
            case WidgetType.TurfGuardMoisture:
            case WidgetType.TurfGuardSalinity:
            case WidgetType.TurfGuardTemperature:
                widget.rows = widget.rows || 1;
                widget.cols = widget.cols || 1;
                widget.maxItemCols = 3;
                widget.maxItemRows = 1;
                break;

            // 2x2 Fixed
            case WidgetType.WeatherRadar:
            case WidgetType.GreenSight:
            case WidgetType.SoilScoutMap:
            // case WidgetType.Notes:
                widget.cols = !this.isMobileGridDisplay ? 2 : 1;
                widget.rows = !this.isMobileGridDisplay ? 2 : 1;
                widget.resizeEnabled = false;
                break;

            // 2x1 - Resizable Rows (min/max cols = 2)
            case WidgetType.TurfCloudLaborStats:
                widget.rows = widget.rows || 1;
                widget.cols = 2;
                widget.minItemCols = 2;
                widget.maxItemRows = 2;
                widget.maxItemCols = 2;
                break;

            // 1x2 - Fixed Rows
            case WidgetType.TaskTrackerTurfRad:
                widget.rows = 2;
                widget.cols = 1;
                widget.resizeEnabled = false;
                break;

            // 1x2 - Resizable Rows
            case WidgetType.TurfCloudCourseTrakk:
            case WidgetType.WeatherGraphs:
            case WidgetType.Playbooks:
            case WidgetType.TaskTrackerLaborSummary:
                widget.rows = widget.rows || 2;
                widget.cols = widget.cols || 1;
                widget.maxItemCols = 1;
                widget.maxItemRows = 2;
                break;

            // 1x2 - Resizable Rows, but start with only 1 row (if new)
            case WidgetType.LynxStationStatuses:
                widget.rows = widget.rows || 1;
                widget.cols = widget.cols || 1;
                widget.maxItemCols = 1;
                widget.maxItemRows = 2;
                break;

        }

        // If mobile, only allow single row widgets and disable resizing.
        if (this.isMobileGridDisplay) {
            widget.rows = 1;
            widget.maxItemRows = 1;
            widget.resizeEnabled = false;
        }

        return widget;
    }

    updateWidgetConfig(widgetType: WidgetType, config: any): Observable<void> {
        const updateWidgetModel = new UpdateWidgetModel(widgetType, config, this.authManager.dashAuthenticatedUser, this.deviceId);
        return this.widgetApiService.updateWidgetConfig(updateWidgetModel);
    }

    // =========================================================================================================================================================
    // Helper Methods
    // =========================================================================================================================================================

    private get deviceId(): string {
        return this.isMobileGridDisplay ? 'mobile' : 'desktop';
    }

    private updateWidgetSelection(selectedTypes: ToroEnums.WidgetType[]) {
        const removedTypes = this._dashboardWidgetsDict[this.deviceId].filter((w => !selectedTypes.includes(w.widgetType))).map(w => w.widgetType);
        setTimeout(() => this.trackWidgetAddRemove(removedTypes, WidgetTrackingEvent.Remove));

        if (selectedTypes.length === 0) this._dashboardWidgetsDict[this.deviceId] = [];

        // Remove any previously selected widgets that are no longer selected.
        this._dashboardWidgetsDict[this.deviceId] = this._dashboardWidgetsDict[this.deviceId].filter((w => selectedTypes.includes(w.widgetType)));

        // Add any newly selected items to the cached list.
        const addedTypes = selectedTypes.filter(t => !this._dashboardWidgetsDict[this.deviceId].map(w => w.widgetType).includes(t));
        addedTypes.forEach(t => {
            this._dashboardWidgetsDict[this.deviceId].push(new DashboardWidgetInfo({ widgetType: t, x: 0, y: 0, height: 1 }));
        });
        setTimeout(() => this.trackWidgetAddRemove(addedTypes, WidgetTrackingEvent.Add));

        // Persist the list in the db.
        this.saveDashboardWidgets(this._dashboardWidgetsDict[this.deviceId], this.isMobileGridDisplay ? 'mobile' : 'desktop')
            .subscribe(() => {
                // Alert Subscribers
                this.widgetCollectionChange.next({ addedWidgetTypes: addedTypes, removedWidgetTypes: removedTypes });
            }, error => {
                this.dashMessageService.showGenericSaveErrorMessage();
            });
    }

    // TODO: MW - Select the correct set of default widgets.
    private static get defaultDashboardWidgets(): DashboardWidgetInfo[] {
        return [
            // new DashboardWidgetInfo({ widgetType: ToroEnums.WidgetType.LocalWeatherForecast, x: 0, y: 0, height: 1, width: 2 }),
            // new DashboardWidgetInfo({ widgetType: ToroEnums.WidgetType.Evapotranspiration, x: 0, y: 1, height: 1, width: 2 }),
        ];
    }

    private trackWidgetAddRemove(widgets: WidgetType[], trackingEvent: WidgetTrackingEvent) {
        if (!widgets || widgets.length < 1) { return; }

        let widgetName = '';
        const event = trackingEvent === WidgetTrackingEvent.Add ? AnalyticsEvent.WidgetAdded : AnalyticsEvent.WidgetRemoved;

        widgets.forEach(w => {
            switch (w) {
                case WidgetType.DecisionTree:
                    widgetName = AnalyticsEvent.DecisionTreeWidgetName;
                    break;
                case WidgetType.EquipmentTracking:
                    widgetName = AnalyticsEvent.TrackingWidgetName;
                    break;
                case WidgetType.Evapotranspiration:
                    widgetName = AnalyticsEvent.EvapotranspirationWidgetName;
                    break;
                case WidgetType.EzLocator:
                    widgetName = AnalyticsEvent.EzLocatorWidgetName;
                    break;
                case WidgetType.FrostWarning:
                    widgetName = AnalyticsEvent.FrostWarningWidgetName;
                    break;
                case WidgetType.Gcsaa:
                    widgetName = AnalyticsEvent.GcsaaWidgetName;
                    break;
                case WidgetType.GcsaaAlt:
                    widgetName = AnalyticsEvent.GcsaaAltWidgetName;
                    break;
                case WidgetType.GeoFencing:
                    widgetName = AnalyticsEvent.GeoFencingWidgetName;
                    break;
                case WidgetType.GreenSight:
                    widgetName = AnalyticsEvent.GreenSightWidgetName;
                    break;
                case WidgetType.LocalWeatherForecast:
                    widgetName = AnalyticsEvent.LocalWeatherForecastWidgetName;
                    break;
                case WidgetType.DtnWeather:
                    widgetName = AnalyticsEvent.DtnWeatherForecastWidgetName;
                    break;
                case WidgetType.LynxLocalCurrentWeather:
                    widgetName = AnalyticsEvent.LynxLocalCurrentWeatherWidgetName;
                    break;
                case WidgetType.LynxLocalWeatherGraphs:
                    widgetName = AnalyticsEvent.LynxLocalWeatherGraphsWidgetName;
                    break;
                case WidgetType.LynxPumpStations:
                    widgetName = AnalyticsEvent.LynxPumpStationsWidgetName;
                    break;
                case WidgetType.LynxRunningStations:
                    widgetName = AnalyticsEvent.LynxRunningStationsWidgetName;
                case WidgetType.LynxStationStatuses:
                    widgetName = AnalyticsEvent.LynxStationStatusesWidgetName;
                    break;
                case WidgetType.MyTurfMaintenance:
                    widgetName = AnalyticsEvent.MyTurfMaintenanceWidgetName;
                    break;
                case WidgetType.MyTurfOrders:
                    widgetName = AnalyticsEvent.MyTurfOrdersWidgetName;
                    break;
                case WidgetType.Notes:
                    widgetName = AnalyticsEvent.NotesWidgetName;
                    break;
                case WidgetType.Nsn:
                    widgetName = AnalyticsEvent.NsnWidgetName;
                    break;
                case WidgetType.PerryWeather:
                    widgetName = AnalyticsEvent.PerryWeatherWidgetName;
                    break;
                case WidgetType.Playbooks:
                    widgetName = AnalyticsEvent.PogoWidgetName;
                    break;
                case WidgetType.Pogo:
                    widgetName = AnalyticsEvent.PlaybooksWidgetName;
                    break;
                case WidgetType.SoilScoutMoisture:
                    widgetName = AnalyticsEvent.SoilScoutMoistureWidgetName;
                    break;
                case WidgetType.SoilScoutSalinity:
                    widgetName = AnalyticsEvent.SoilScoutSalinityWidgetName;
                    break;
                case WidgetType.SoilScoutTemperature:
                    widgetName = AnalyticsEvent.SoilScoutTemperatureWidgetName;
                    break;
                case WidgetType.SoilScoutMap:
                    widgetName = AnalyticsEvent.SoilScoutMapWidgetName;
                    break;
                case WidgetType.SpectrumMoisture:
                    widgetName = AnalyticsEvent.SpectrumMoistureName;
                    break;
                case WidgetType.TaskTrackerLaborSummary:
                    widgetName = AnalyticsEvent.TaskTrackerLaborSummary;
                    break;
                case WidgetType.TaskTrackerDailyMeasurements:
                    widgetName = AnalyticsEvent.TaskTrackerDailyMeasurements;
                    break;
                case WidgetType.TaskTrackerSafety:
                    widgetName = AnalyticsEvent.TaskTrackerSafetyAndTraining;
                    break;
                case WidgetType.TurfCloudCourseTrakk:
                    widgetName = AnalyticsEvent.TurfCloudCourseTrakkWidgetName;
                    break;
                case WidgetType.TurfCloudLaborStats:
                    widgetName = AnalyticsEvent.TurfCloudLaborStatsWidgetName;
                    break;
                case WidgetType.TurfGuardMoisture:
                    widgetName = AnalyticsEvent.TurfGuardMoistureWidgetName;
                    break;
                case WidgetType.TurfGuardSalinity:
                    widgetName = AnalyticsEvent.TurfGuardSalinityWidgetName;
                    break;
                case WidgetType.TurfGuardTemperature:
                    widgetName = AnalyticsEvent.TurfGuardTemperatureWidgetName;
                    break;
                case WidgetType.WeatherGraphs:
                    widgetName = AnalyticsEvent.WeatherGraphsWidgetName;
                    break;
                case WidgetType.WeatherRadar:
                    widgetName = AnalyticsEvent.WeatherRadarWidgetName;
                    break;
                default:
                    widgetName = 'Unknown Widget';
                    break;
            }

            this.analyticsService.widgetEvent(event, AnalyticsCategory.Widgets, widgetName);
        });

    }
}
